import React, { useState } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { DropZone } from '../DropZone/DropZone';
import useUploadFile from '../../../core/helpers/uploadFile';
import IFileUploadResp from '../../../api/interfaces';
import ContainerType from '../../../api/enums/ContainerType';
import { useNavigate } from 'react-router-dom';
import Loading from '../../core/loading';
import { ValidateTicket } from '../../../api/ticketApi/ticketImport';
import useAxios from 'axios-hooks';
import { useAppSelector } from '../../../store/hooks';
import { RootState } from '../../../store/store';
import { PreImportTicketExtended, TicketImportSummarization, TicketImportValidationResponse } from './types';

export const ProcessUpload: React.FC<{
  setProcessState: React.Dispatch<React.SetStateAction<number>>,
  setFileId: React.Dispatch<React.SetStateAction<number | null>>,
  setValidationData: React.Dispatch<React.SetStateAction<PreImportTicketExtended[]>>
  setImportSummarization: React.Dispatch<React.SetStateAction<TicketImportSummarization>>
}> = ({
  setProcessState,
  setValidationData,
  setFileId,
  setImportSummarization,
}) => {
  const upload = useUploadFile();
  const navigate = useNavigate();
  const [preValidationError, setPreValidationError] = useState<string | undefined>(undefined);
  const [onLoading, setOnLoading] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const account = useAppSelector(((state: RootState) => state.user.account));
  const accountId = account?.accountId;
  const [, refetch] = useAxios<TicketImportValidationResponse>(ValidateTicket( accountId, null), { manual: true });

  const testId = 'ticket-import-process';

  const handleUploadFile = async (
  ): Promise<void> => {

    setOnLoading(true);
    setPreValidationError(undefined);
    
    if (selectedFile) {
      const { id: fileId }: IFileUploadResp = await upload(
        ContainerType.TICKET_IMPORT,
        selectedFile,
      );

      const { data } = await refetch(ValidateTicket( accountId, { fileId } ));

      setOnLoading(false);
      setSelectedFile(null);

      if(data && data.preValidation && data.preValidation.isValid === false) {
        setPreValidationError(data.preValidation.message);
      }else if(data && data.preValidation && data.mainValidation.isValid === false) {
        setValidationData(data.mainValidation.invalidRowData);
        setFileId(fileId);
        setProcessState(1);
      }else {
        setFileId(fileId);
        setImportSummarization(data.summarization);
        setProcessState(2);
      }
    }
  };

  return <Grid container justifyContent={ 'center' } mt={ 10 }>
    <Grid item xs={ 12 } lg={ 6 }>
      { onLoading && <Loading /> }
      { !onLoading &&  <Grid mb={ 3 } item xs={ 12 } lg={ 12 }>
        <Typography>
          Before using the ticket import, please read the <a target="_blank" className="blueText" href='https://drive.google.com/file/d/1bvZQh1lZxkKSYInRXPLoWDw4Ln3q9NL_/view?usp=sharing' rel="noreferrer">User Guide</a>
        </Typography>
      </Grid> }
      { !onLoading && <DropZone
        error={ preValidationError }
        onClear={ setPreValidationError }
        options={ { 
          disabled: onLoading,
          accept: {
            'text/csv': ['.csv'],
            'application/csv': ['.csv'],
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
            'application/vnd.ms-excel': ['.xlsx'],
          },
          maxFiles: 1,
          maxSize: 10000000, 
          onDropRejected(error) {
            console.error(error);
          },
          onDrop(acceptedFiles) {
            if(acceptedFiles && acceptedFiles.length >0) {
              return new Promise(async (res) => {
                setSelectedFile(acceptedFiles[0] as File);
                res(true);
              });
            }
          },
        } } /> }
    </Grid>
    <Grid container justifyContent={ 'center' } spacing={ 2 } mt={ 6 }>
      <Grid item xs={ 12 } lg={ 3 }>  
        <Button onClick={ () =>  {
          navigate('/tickets/import');
        } }  
        className='textButton'
        color="primary" 
        fullWidth={ true }
        variant="contained" 
        data-testid={ `${testId}Page-${testId}Button` }>
            Back to the import list
        </Button>
      </Grid>
      <Grid item xs={ 12 } lg={ 3 }>  
        <Button onClick={ handleUploadFile }  
          className='textButton'
          color="success" 
          disabled={ !selectedFile || onLoading }
          fullWidth={ true }
          variant="contained" 
          data-testid={ `${testId}Page-${testId}Button` }>
             Upload
        </Button>
      </Grid>
    </Grid>
  </Grid>;
};
