import { Alert, AlertColor, Card, Grid, Typography } from '@mui/material';
import React from 'react';
import { DropzoneOptions, ErrorCode, useDropzone } from 'react-dropzone';
import './styles.css';
import { isEmpty } from 'lodash';

export const DropZone: React.FC<{
  options?: DropzoneOptions;
  error?: string;
  onClear: React.Dispatch<React.SetStateAction<string | undefined>>;
}> = ({ options, error, onClear }) => {
  const { acceptedFiles, getRootProps, getInputProps, fileRejections } =
    useDropzone({
      ...options,
    });

  const errorCode: Record<ErrorCode, string> = {
    'file-invalid-type':
      'The selected file type is invalid, acceptable format: .csv or xlsx',
    'file-too-large': '',
    'file-too-small': '',
    'too-many-files': '',
  };

  const getMessage = () => {
    let message = '';
    let severity: AlertColor = 'error';
    const onClose = () => {
      onClear(undefined);
    };

    const topFileRejectionMessage =
      fileRejections &&
      fileRejections.length > 0 &&
      fileRejections[0].errors &&
      fileRejections[0].errors.length > 0 &&
      fileRejections[0].errors[0].code
        ? fileRejections[0].errors[0].code
        : null;

    const file =
      acceptedFiles &&
      acceptedFiles.map((file) => `Selected file: ${file.name}`).join('');

    if (file) {
      message = file;
      severity = 'success';
    }

    if (topFileRejectionMessage) {
      message = errorCode[topFileRejectionMessage as ErrorCode];
      severity = 'error';
    }

    if (error) {
      message = error;
      severity = 'error';
    }

    if (isEmpty(message)) {
      return null;
    } else {
      return (
        <Alert onClose={onClose} severity={severity}>
          {message}
        </Alert>
      );
    }
  };

  return (
    <Card>
      <Grid container className="container">
        <Grid item xs={12} textAlign={'center'}>
          <div {...getRootProps({ className: 'dropzone-in' })}>
            <input {...getInputProps()} />
            {getMessage() ?? (
              <Typography>{`Drag 'n' drop some files here, or click to select files`}</Typography>
            )}
          </div>
        </Grid>
      </Grid>
    </Card>
  );
};
